import RouteInfo from '@ember/routing/route-info';
import RouterService from '@ember/routing/router-service';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import WindowService from 'mobile-web/services/window';

import { AnalyticsProperties } from './analytics';
import BootstrapService from './bootstrap';
import DeviceService from './device';
import ErrorService from './error';
import FeaturesService from './features';
import SessionService from './session';

export type PathConfig = {
  cookieName: string;
  href: (to: RouteInfo) => string;
  toName: string;
};

export const CONFIGS: PathConfig[] = [
  {
    cookieName: 'olo-serve-next-licenses',
    href: () => '/open-source-licenses',
    toName: 'open-source-licenses',
  },
  {
    cookieName: 'srvnxtty',
    href: to => `/thank-you/${to.params.order_id}`,
    toName: 'thank-you',
  },
  {
    cookieName: 'srvnxtlogin',
    href: () => '/login',
    toName: 'login',
  },
  {
    cookieName: 'srvnxtloginupgrade',
    href: () => '/user/upgrade',
    toName: 'user-upgrade',
  },
  {
    cookieName: 'srvnxtlocationsearch',
    href: () => '/search',
    toName: 'vendor-search-results',
  },
  {
    cookieName: 'srvnxtlocationsearchpage',
    href: () => '/search',
    toName: 'vendor-search-results',
  },
  {
    cookieName: 'srvnxtmenu',
    href: to => `/menu/${to.parent!.params.vendor_slug}`,
    toName: 'menu.vendor.index',
  },
];

export default class MigrationService extends Service {
  // Service injections
  @service bootstrap!: BootstrapService;
  @service features!: FeaturesService;
  @service router!: RouterService;
  @service window!: WindowService;
  @service device!: DeviceService;
  @service error!: ErrorService;
  @service session!: SessionService;

  // Untracked properties

  // Tracked properties
  @tracked isRedirecting = false;

  // Getters and setters
  get isUserUpgradePageEnabled() {
    if (!this.device.isHybrid) {
      return true;
    }

    const serveNextConfig = this.bootstrap.data?.serveNextConfig;
    return (
      serveNextConfig?.pathConfigs.find(c => c.cookieName === 'srvnxtloginupgrade')
        ?.fullyMigrated ?? false
    );
  }

  // Lifecycle methods
  constructor() {
    super(...arguments);

    this.router.on('routeWillChange', async transition => {
      const props = await this.getPropsFor(transition.to?.name);

      if (props?.shouldTransition) {
        this.isRedirecting = true;

        const href = props.href(transition.to);

        // we already know we redirecting so abort early before await
        transition.abort();

        /*
        the routeWillChange doesn't not expect promise returned
        therefore calling await here release app to continue its exceution.
        Aborting route transition should happen before any awaits in this func
        */
        if (await this.tryRenderToServeNextHybrid(href)) {
          return;
        }
        this.window.location().assign(href);
      }
    });

    // This additional event handler is for when users land on the index page.
    // I found that the routeWillChange event handler above was not getting
    // instantiated until after routing in the app lifecycle, and thus it
    // would not fire in time to redirect users to the search page when landing
    // on the home page. I tried creating an instance-initializer, which worked
    // for the event handler, but then we did not yet have access to LD FF's,
    // so it was too early to know whether we wanted to redirect the user anyway.
    // Rather than messing with where we initialize LD FF data, I chose to add
    // this extra event handler, which will be called after both service instantiation
    // and FF instantiation in the app's life cycle.
    this.router.on('routeDidChange', async transition => {
      const props = await this.getPropsFor('vendor-search-results');
      if (this.serveNextLocationSeachPageEnabled && transition.to?.name === 'index') {
        this.trackServeNextLocationAsAbFlagOnce();
      }

      if (props?.shouldTransition && transition.to?.name === 'index' && this.skipHomePage) {
        this.isRedirecting = true;

        const href = '/search';

        // we already know we redirecting so abort early before 'await'
        transition.abort();

        /*
        same as for routeWillChange
        */
        if (await this.tryRenderToServeNextHybrid(href)) {
          return;
        }

        this.window.location().assign(href);
      }
    });
  }

  // Other methods
  async tryRenderToServeNextHybrid(href: string) {
    if (
      !this.device.isHybrid ||
      this.features.flags['serve-next-hybrid-apps-disabled'] ||
      !this.device.hasServeNextFiles()
    ) {
      this.isRedirecting = false;
      return false;
    }

    try {
      await this.device.renderHybridServeNext(`${href}${this.window.location().search}`);
    } catch (e) {
      this.error.sendExternalError(e);
      this.isRedirecting = false;
      return false;
    }

    this.isRedirecting = false;
    return true;
  }
  trackServeNextLocationAsAbFlagOnce() {
    if (!this.session.nextLocationIsTracked && this.features.analytics) {
      this.features.analytics.trackEvent(`LD abtest-serve-next-location-search`, () => ({
        [AnalyticsProperties.FlagValue]: !document.cookie.includes(`srvnxtlocationsearchpage=1`)
          ? 'Control'
          : this.features.flags['abtest-new-location-search-page-olo-93790'],
        [AnalyticsProperties.IsControl]: !document.cookie.includes(`srvnxtlocationsearchpage=1`),
        [AnalyticsProperties.FlagUserKey]: this.features.ldUserContext?.uniqueId,
      }));

      this.session.nextLocationIsTracked = true;
    }
  }

  // Tasks

  // Actions and helpers
  get skipHomePage() {
    const variation = this.features.flags['abtest-new-location-search-page-olo-93790'] as string;
    return variation === 'B';
  }

  async getPropsFor(toName?: string) {
    const clientConfig = CONFIGS.filter(c => c.toName === toName);
    if (!clientConfig || clientConfig.length === 0) {
      return undefined;
    }

    const serveNextConfig = this.bootstrap.data?.serveNextConfig;

    const serverConfig = serveNextConfig?.pathConfigs.find(c =>
      clientConfig.some(client => client.cookieName === c.cookieName)
    );
    if (!serverConfig) {
      return undefined;
    }

    const isDisabled = serverConfig.flagValue === -1 || serveNextConfig!.isDisabled;

    return {
      href: clientConfig[0].href,
      shouldTransition:
        !isDisabled &&
        (this.device.isHybrid
          ? serverConfig.fullyMigrated ||
            (await this.device.storageGet(serverConfig.cookieName)) === '1'
          : serverConfig.fullyMigrated ||
            serverConfig.flagValue >= 100 ||
            document.cookie.includes(`${serverConfig.cookieName}=1`)),
    };
  }

  get serveNextLocationSeachPageEnabled() {
    const serveNextConfig = this.bootstrap.data?.serveNextConfig;
    const serverConfig = serveNextConfig?.pathConfigs.find(c =>
      c.cookieName?.includes('srvnxtlocationsearch')
    );
    if (!serverConfig) {
      return undefined;
    }
    return !(serverConfig?.flagValue === -1 || serveNextConfig!.isDisabled);
  }
}
declare module '@ember/service' {
  interface Registry {
    migration: MigrationService;
  }
}
